import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('form.store.generalInformation'),
    name: 0,
    createDateItem: [{
      span: 6,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 6,
      prop: 'charge_gst_separately',
      name: 'switch',
      value: false,
      label: i18n.t('form.store.chargeGSTSeparately'),
      Object: {}
    }, {
      span: 6,
      prop: 'searchable',
      name: 'switch',
      value: false,
      label: i18n.t('form.product.searchable'),
      Object: {}
    }, {
      span: 6,
      prop: 'official',
      name: 'switch',
      value: false,
      label: i18n.t('form.store.official'),
      Object: {}
    }, {
      span: 6,
      prop: 'automated_order_processing',
      name: 'switch',
      value: false,
      label: i18n.t('form.store.automatedOrderProcessing'),
      Object: {}
    }, {
      span: 18,
      prop: 'sms_notification',
      name: 'switch',
      value: false,
      label: i18n.t('form.store.smsNotification'),
      Object: {}
    }, {
      span: 8,
      prop: 'sms_number',
      name: 'input',
      label: i18n.t('form.store.smsNotificationNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'admin_users',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.store.adminUser'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        multiple: true,
        'collapse-tags': true
      }
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.product.cnName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'name_en',
      name: 'input',
      label: i18n.t('form.product.enName'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'supported_item_types',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.store.itemTypes'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        multiple: true,
        'collapse-tags': true
      }
    }, {
      span: 24,
      prop: 'logo',
      name: 'image',
      value: [],
      label: i18n.t('form.store.logo'),
      Object: {
        limit: 1,
        size: `${i18n.t('form.product.recommendedSize')}: 120x120`
      }
    }, {
      span: 8,
      prop: 'delivery_time_begin',
      name: 'time',
      options: [],
      label: i18n.t('form.store.operationTimeStart'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'delivery_time_end',
      name: 'time',
      options: [],
      label: i18n.t('form.store.operationTimeEnd'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
      }
    }, {
      span: 8,
      prop: 'phone_number',
      name: 'input',
      label: i18n.t('form.store.phoneNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'email',
      name: 'input',
      label: i18n.t('form.store.email'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'paynow_phone',
      name: 'input',
      label: i18n.t('form.store.whatsAppNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'paynow_wechat',
      name: 'input',
      label: i18n.t('form.store.paynowWechat'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'service_fee_rate',
      name: 'input',
      type: 'number',
      value: 0,
      label: i18n.t('form.store.serviceFee'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'rank',
      name: 'input',
      type: 'number',
      value: 0,
      label: i18n.t('form.common.rank'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'rule',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.store.shopRuleszh'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.TEXT_AREA.MAX_LENGTH.COMMON,
        row: 4
      }
    }, {
      span: 24,
      prop: 'rule_en',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.store.shopRulesen'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.TEXT_AREA.MAX_LENGTH.COMMON,
        row: 4
      }
    }, {
      span: 8,
      prop: 'global_labels',
      name: 'select',
      options: [],
      label: i18n.t('form.store.shopCategories'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        multiple: true,
        'collapse-tags': true
      }
    }]
  }, {
    title: i18n.t('form.store.displayInformation'),
    name: 1,
    createDateItem: [{
      span: 6,
      prop: 'hide_sold_count',
      name: 'switch',
      value: false,
      label: i18n.t('form.store.hideSoldCount'),
      Object: {}
    }, {
      span: 8,
      prop: 'display_type',
      name: 'select',
      options: [],
      label: i18n.t('form.store.shopDisplayType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'shop_category_items_sorting_method',
      name: 'select',
      options: [],
      label: i18n.t('form.store.productSortingRule'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'tag_choice',
      name: 'select',
      options: [],
      label: i18n.t('form.store.tabChoice'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }]
  },
  // {
  //   title: i18n.t('form.store.websiteInformation'),
  //   name: 2,
  //   createDateItem: [{
  //     span: 8,
  //     prop: 'sub_domain',
  //     name: 'input',
  //     label: i18n.t('form.store.shopSubDomain'),
  //     placeholder: i18n.t('placeholder.defaultInput'),
  //     Object: {
  //       maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
  //     }
  //   }, {
  //     span: 8,
  //     prop: 'theme_mode',
  //     name: 'select',
  //     options: [],
  //     label: i18n.t('form.store.themeMode'),
  //     placeholder: i18n.t('placeholder.defaultSelect'),
  //     Object: {
  //       filterable: true
  //     }
  //   }, {
  //     span: 8,
  //     prop: 'theme_color',
  //     name: 'color',
  //     label: i18n.t('form.store.themeColor'),
  //     Object: {}
  //   }]
  // },
  {
    title: i18n.t('form.store.deliveryInformation'),
    name: 2,
    createDateItem: [{
      span: 24,
      prop: 'support_delivery',
      name: 'switch',
      value: false,
      label: i18n.t('form.store.supportDelivery'),
      Object: {}
    }, {
      span: 8,
      prop: 'mini_delivery_amount_f',
      name: 'input',
      label: i18n.t('form.store.minDeliveryAmount'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'free_delivery_amount_f',
      name: 'input',
      label: i18n.t('form.store.freeDeliveryAmount'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'delivery_date_display_mode',
      name: 'select',
      options: [],
      label: i18n.t('form.store.deliveryDateDisplayMode'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'delivery_date_display_range',
      name: 'input',
      type: 'number',
      label: i18n.t('form.store.numberOfDays'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        visible: 'delivery_date_display_mode',
        visibleValue: 0
      }
    }, {
      span: 8,
      prop: 'delivery_cutoff_date',
      name: 'date',
      label: i18n.t('form.store.cutoffDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        visible: 'delivery_date_display_mode',
        visibleValue: 1
      }
    }, {
      span: 24,
      prop: 'shop_no_delivery_dates_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.shopNoDeliveryDate'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'start_date',
        name: 'date',
        label: i18n.t('form.store.startDate'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'end_date',
        name: 'date',
        label: i18n.t('form.store.endDate'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }]
    }, {
      span: 24,
      prop: 'host_delivery_time_slots_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.deliveryTimeSlot'),
      Object: {
        delete: '_destroy',
        'array-label-width': '140px'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'slot_begin_time',
        name: 'time',
        label: i18n.t('form.store.startDate'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'slot_end_time',
        name: 'time',
        label: i18n.t('form.store.endDate'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'advance_time',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.cutOffMin'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'shipping_num',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.quota'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'warning_num',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.warningQuota'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'weekdays',
        name: 'select',
        options: [],
        label: i18n.t('form.store.deliveryWeekdays'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          filterable: true,
          multiple: true,
          'collapse-tags': true
        }
      }, {
        span: 12,
        prop: 'surcharge_f',
        name: 'input',
        label: i18n.t('form.store.surcharge'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }]
    }, {
      span: 24,
      prop: 'delivery_tips',
      name: 'input',
      label: i18n.t('form.store.deliveryTipszh'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'delivery_tips_en',
      name: 'input',
      label: i18n.t('form.store.deliveryTipsen'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'express_fee_mode',
      name: 'select',
      options: [],
      label: i18n.t('form.store.deliveryFeeCalculationMethod'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.deliveryFeeCalculationMethod') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'shop_expresses_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.deliveryFeebyDistance'),
      Object: {
        delete: '_destroy',
        'array-label-width': '180px',
        visibleChange: [
          {express_fee_mode: 'express_fee_mode_by_distance'}
        ]
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'min_distance',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.startDistanceInclusive'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 12,
        prop: 'max_distance',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.endDistanceExclusive'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 12,
        prop: 'fee_f',
        name: 'input',
        label: i18n.t('form.store.expressFee'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }]
    }, {
      span: 24,
      prop: 'shop_express_fee_by_amounts_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.deliveryFeebyOrderAmount'),
      Object: {
        delete: '_destroy',
        'array-label-width': '180px',
        visibleChange: [
          {express_fee_mode: 'express_fee_mode_by_amount'}
        ]
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'start_amount_f',
        name: 'input',
        label: i18n.t('form.store.startAmountInclusive'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 12,
        prop: 'end_amount_f',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.endAmountExclusive'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 12,
        prop: 'fee_f',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.expressFee'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }]
    }
    // , {
    //   span: 8,
    //   prop: 'third_party_delivery_services',
    //   name: 'select',
    //   options: [],
    //   value: [],
    //   label: i18n.t('form.store.thirdPartyDelivery'),
    //   placeholder: i18n.t('placeholder.defaultSelect'),
    //   Object: {
    //     filterable: true,
    //     multiple: true,
    //     'collapse-tags': true
    //   }
      // }, {
      //   span: 8,
      //   prop: 'partner_email',
      //   name: 'input',
      //   label: i18n.t('form.store.partnersEmail'),
      //   placeholder: i18n.t('placeholder.defaultInput'),
      //   Object: {
      //     maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      //   }
      // }, {
      //   span: 24,
      //   prop: 'driver_instruction',
      //   name: 'input',
      //   type: 'textarea',
      //   label: i18n.t('form.store.instructionforDriver'),
      //   placeholder: i18n.t('placeholder.defaultInput'),
      //   Object: {
      //     maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      //   }
    // }
    ]
  }, {
    title: i18n.t('form.store.deliveryZoneSettings'),
    name: 3,
    client: 'combine',
    createDateItem: [{
      span: 24,
      prop: 'home_delivery_enabled',
      name: 'switch',
      value: true,
      label: i18n.t('form.store.supportZoneDelivery'),
      Object: {}
    }, {
      span: 8,
      prop: 'min_home_delivery_amount_f',
      name: 'input',
      label: i18n.t('form.store.minDeliveryAmount'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'free_home_delivery_amount_f',
      name: 'input',
      label: i18n.t('form.store.freeDeliveryAmount'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'home_delivery_date_display_mode',
      name: 'select',
      options: [],
      label: i18n.t('form.store.deliveryDateDisplayMode'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'home_delivery_date_display_range',
      name: 'input',
      type: 'number',
      label: i18n.t('form.store.numberOfDays'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'home_delivery_date_display_mode',
        visibleValue: 0
      }
    }, {
      span: 8,
      prop: 'home_delivery_cutoff_date',
      name: 'date',
      label: i18n.t('form.store.cutoffDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        visible: 'home_delivery_date_display_mode',
        visibleValue: 1
      }
    }, {
      span: 24,
      prop: 'home_delivery_shop_no_delivery_dates_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.shopNoDeliveryDate'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'start_date',
        name: 'date',
        label: i18n.t('form.store.startDate'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'end_date',
        name: 'date',
        label: i18n.t('form.store.endDate'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }]
    }, {
      span: 24,
      prop: 'home_delivery_time_slots_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.zoneDeliveryTimeSlot'),
      Object: {
        delete: '_destroy',
        'array-label-width': '140px'
      },
      createDateFirstItem: [{
        span: 24,
        prop: 'delivery_zone_id',
        name: 'select',
        options: [],
        label: i18n.t('form.store.zoneName'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          filterable: true,
          value: 'id',
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.zoneName') }) }
          ]
        }
      }, {
        span: 12,
        prop: 'slot_begin_time',
        name: 'time',
        label: i18n.t('form.store.startDate'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.startDate') }) }
          ]
        }
      }, {
        span: 12,
        prop: 'slot_end_time',
        name: 'time',
        label: i18n.t('form.store.endDate'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.endDate') }) }
          ]
        }
      }, {
        span: 12,
        prop: 'advance_time',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.cutOffMin'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.cutOffMin') }) }
          ]
        }
      }, {
        span: 12,
        prop: 'shipping_num',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.quota'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.quota') }) }
          ]
        }
      }, {
        span: 12,
        prop: 'warning_num',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.warningQuota'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.warningQuota') }) }
          ]
        }
      }, {
        span: 12,
        prop: 'weekdays',
        name: 'select',
        options: [],
        value: [],
        label: i18n.t('form.store.deliveryWeekdays'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {
          filterable: true,
          multiple: true,
          'collapse-tags': true,
          rules: [
            { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.deliveryWeekdays') }) }
          ]
        }
      }, {
        span: 12,
        prop: 'surcharge_f',
        name: 'input',
        label: i18n.t('form.store.surcharge'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 24,
        prop: 'shop_no_delivery_dates_attributes',
        name: 'array',
        value: [],
        label: i18n.t('form.store.suspendDeliveryTimeSlot'),
        Object: {
          delete: '_destroy',
          'array-label-width': '140px'
        },
        createDateFirstItem: [{
          span: 12,
          prop: 'start_date',
          name: 'date',
          label: i18n.t('form.store.suspendDeliveryStartDate'),
          placeholder: i18n.t('placeholder.defaultSelect'),
          Object: {
            rules: [
              { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.suspendDeliveryStartDate') }) }
            ]
          }
        }, {
          span: 12,
          prop: 'end_date',
          name: 'date',
          label: i18n.t('form.store.suspendDeliveryEndDate'),
          placeholder: i18n.t('placeholder.defaultSelect'),
          Object: {
            rules: [
              { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.suspendDeliveryEndDate') }) }
            ]
          }
        }]
      }]
    }, {
      span: 24,
      prop: 'home_delivery_tips',
      name: 'input',
      label: i18n.t('form.store.deliveryTipszh'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'home_delivery_tips_en',
      name: 'input',
      label: i18n.t('form.store.deliveryTipsen'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'home_delivery_fee_mode',
      name: 'select',
      options: [],
      label: i18n.t('form.store.deliveryFeeCalculationMethod'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true,
        rules: [
          { required: true, message: i18n.t('text.require', { name: i18n.t('form.store.deliveryFeeCalculationMethod') }) }
        ]
      }
    }, {
      span: 24,
      prop: 'home_delivery_shop_expresses_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.deliveryFeebyDistance'),
      Object: {
        delete: '_destroy',
        'array-label-width': '180px',
        visible: 'home_delivery_fee_mode',
        visibleValue: 'express_fee_mode_by_distance'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'min_distance',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.startDistanceInclusive'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 12,
        prop: 'max_distance',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.endDistanceExclusive'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 12,
        prop: 'fee_f',
        name: 'input',
        label: i18n.t('form.store.expressFee'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }]
    }, {
      span: 24,
      prop: 'home_delivery_shop_express_fee_by_amounts_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.deliveryFeebyOrderAmount'),
      Object: {
        delete: '_destroy',
        'array-label-width': '180px',
        visible: 'home_delivery_fee_mode',
        visibleValue: 'express_fee_mode_by_amount'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'start_amount_f',
        name: 'input',
        label: i18n.t('form.store.startAmountInclusive'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 12,
        prop: 'end_amount_f',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.endAmountExclusive'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 12,
        prop: 'fee_f',
        name: 'input',
        type: 'number',
        label: i18n.t('form.store.expressFee'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }]
    }
    // , {
    //   span: 8,
    //   prop: 'third_party_delivery_services',
    //   name: 'select',
    //   options: [],
    //   value: [],
    //   label: i18n.t('form.store.thirdPartyDelivery'),
    //   placeholder: i18n.t('placeholder.defaultSelect'),
    //   Object: {
    //     filterable: true,
    //     multiple: true,
    //     'collapse-tags': true
    //   }
      // }, {
      //   span: 8,
      //   prop: 'partner_email',
      //   name: 'input',
      //   label: i18n.t('form.store.partnersEmail'),
      //   placeholder: i18n.t('placeholder.defaultInput'),
      //   Object: {
      //     maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      //   }
      // }, {
      //   span: 24,
      //   prop: 'driver_instruction',
      //   name: 'input',
      //   type: 'textarea',
      //   label: i18n.t('form.store.instructionforDriver'),
      //   placeholder: i18n.t('placeholder.defaultInput'),
      //   Object: {
      //     maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      //   }
    // }
    ]
  }, {
    title: i18n.t('form.store.selfPickupInformation'),
    name: 4,
    createDateItem: [{
      span: 24,
      prop: 'support_pickup',
      name: 'switch',
      value: false,
      label: i18n.t('form.store.supportSelfPickup'),
      Object: {}
    }, {
      span: 8,
      prop: 'interval_time',
      name: 'input',
      type: 'number',
      label: i18n.t('form.store.offsetMin'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'pick_up_advance_time',
      name: 'input',
      type: 'number',
      label: i18n.t('form.store.pickUpAdvanceTimeMin'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'pick_up_display_mode',
      name: 'select',
      options: [],
      label: i18n.t('form.store.pickUpDateDisplayRange'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'pick_up_date_display_range',
      name: 'input',
      type: 'number',
      label: i18n.t('form.store.numberOfDays'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'pick_up_display_mode',
        visibleValue: 0
      }
    }, {
      span: 8,
      prop: 'pick_up_cutoff_date',
      name: 'date',
      label: i18n.t('form.store.pickupCutoff'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'pick_up_display_mode',
        visibleValue: 1
      }
    }, {
      span: 24,
      prop: 'monday_pick_up_times_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.mondaySelfPickupSlots'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'slot_begin_time',
        name: 'time',
        label: i18n.t('form.store.start'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'slot_end_time',
        name: 'time',
        label: i18n.t('form.store.end'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        prop: 'weekday',
        value: 'monday',
        Object: {}
      }]
    }, {
      span: 24,
      prop: 'tuesday_pick_up_times_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.tuesdaySelfPickupSlots'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'slot_begin_time',
        name: 'time',
        label: i18n.t('form.store.start'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'slot_end_time',
        name: 'time',
        label: i18n.t('form.store.end'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        prop: 'weekday',
        value: 'tuesday',
        Object: {}
      }]
    }, {
      span: 24,
      prop: 'wednesday_pick_up_times_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.wednesdaySelfPickupSlots'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'slot_begin_time',
        name: 'time',
        label: i18n.t('form.store.start'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'slot_end_time',
        name: 'time',
        label: i18n.t('form.store.end'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        prop: 'weekday',
        value: 'wednesday',
        Object: {}
      }]
    }, {
      span: 24,
      prop: 'thursday_pick_up_times_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.thursdaySelfPickupSlots'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'slot_begin_time',
        name: 'time',
        label: i18n.t('form.store.start'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'slot_end_time',
        name: 'time',
        label: i18n.t('form.store.end'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        prop: 'weekday',
        value: 'thursday',
        Object: {}
      }]
    }, {
      span: 24,
      prop: 'friday_pick_up_times_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.fridaySelfPickupSlots'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'slot_begin_time',
        name: 'time',
        label: i18n.t('form.store.start'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'slot_end_time',
        name: 'time',
        label: i18n.t('form.store.end'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        prop: 'weekday',
        value: 'friday',
        Object: {}
      }]
    }, {
      span: 24,
      prop: 'saturday_pick_up_times_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.saturdaySelfPickupSlots'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'slot_begin_time',
        name: 'time',
        label: i18n.t('form.store.start'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'slot_end_time',
        name: 'time',
        label: i18n.t('form.store.end'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        prop: 'weekday',
        value: 'saturday',
        Object: {}
      }]
    }, {
      span: 24,
      prop: 'sunday_pick_up_times_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.sundaySelfPickupSlots'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 12,
        prop: 'slot_begin_time',
        name: 'time',
        label: i18n.t('form.store.start'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        span: 12,
        prop: 'slot_end_time',
        name: 'time',
        label: i18n.t('form.store.end'),
        placeholder: i18n.t('placeholder.defaultSelect'),
        Object: {}
      }, {
        prop: 'weekday',
        value: 'sunday',
        Object: {}
      }]
    }, {
      span: 24,
      prop: 'self_pickup_tips',
      name: 'input',
      label: i18n.t('form.store.selfPickupTipszh'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'self_pickup_tips_en',
      name: 'input',
      label: i18n.t('form.store.selfPickupRipsen'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }]
  }, {
    title: i18n.t('form.decoration.thirdPartyService'),
    name: 5,
    createDateItem: [{
      span: 8,
      prop: 'POS_vendor',
      value: 'None',
      name: 'select',
      options: [{
        name: 'Weeboo',
        value: 'Weeboo'
      }, {
        name: 'iChef',
        value: 'iChef'
      }, {
        name: 'None',
        value: 'None'
      }],
      label: i18n.t('form.decoration.POSvendor'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        clearable: false
      }
    }, {
      span: 8,
      prop: 'merchant_id',
      name: 'input',
      label: i18n.t('form.decoration.merchantId'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'POS_vendor',
        visibleValue: 'Weeboo,iChef'
      }
    }, {
      span: 8,
      prop: 'merchant_secret_key',
      name: 'input',
      label: i18n.t('form.decoration.secretKey'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'POS_vendor',
        visibleValue: 'Weeboo,iChef'
      }
    }, {
      span: 8,
      // prop: 'delivery_vendor',
      prop: 'third_party_delivery_services',
      value: [],
      name: 'select',
      options: [],
      label: i18n.t('form.decoration.deliveryVendor'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        clearable: false,
        multiple: true
      }
    }, {
      span: 8,
      prop: 'grab_express_client_id',
      name: 'input',
      label: i18n.t('form.decoration.clientId'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'third_party_delivery_services'
      }
    }, {
      span: 8,
      prop: 'grab_express_client_secret',
      name: 'input',
      label: i18n.t('form.decoration.secretKey'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'third_party_delivery_services'
      }
    }, {
      span: 24,
      prop: 'driver_instruction',
      name: 'input',
      type: 'textarea',
      label: i18n.t('form.store.instructionforDriver'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }]
  }, {
    title: i18n.t('form.store.paymentInformation'),
    name: 6,
    createDateItem: [{
      span: 8,
      prop: 'paynow_type',
      value: 'unsupported',
      name: 'select',
      label: i18n.t('form.store.payNowType'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        clearable: false
      }
    }, {
      span: 24,
      prop: 'paynow_qrcode',
      name: 'image',
      value: [],
      label: i18n.t('form.store.payNowQRCode'),
      Object: {
        limit: 1,
        size: `${i18n.t('form.product.recommendedSize')}: 120x120`,
        visible: 'paynow_type',
        visibleValue: 'manual'
      }
    }, {
      span: 8,
      prop: 'paynow_code',
      name: 'input',
      label: i18n.t('form.store.payNowUEN'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'paynow_type',
        visibleValue: 'manual'
      }
    }, {
      span: 8,
      prop: 'paynow_phone_number',
      name: 'input',
      label: i18n.t('form.store.payNowPhoneNumber'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'paynow_type',
        visibleValue: 'manual'
      }
    }, {
      span: 8,
      prop: 'stripe_type',
      name: 'select',
      label: i18n.t('form.store.creditCartPayment'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {}
    }, {
      span: 24,
      prop: 'stripe_account_id',
      name: 'input',
      label: i18n.t('form.store.stripeAccount'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'stripe_type',
        visibleValue: 'supported'
      }
    }, {
      span: 24,
      prop: 'cash_on_delivery',
      name: 'switch',
      value: false,
      label: i18n.t('form.store.cashOnDelivery'),
      Object: {}
    }]
  }, {
    title: i18n.t('form.store.merchantGuarantee'),
    name: 7,
    createDateItem: [{
      span: 24,
      prop: 'merchant_guarantees_attributes',
      name: 'array',
      value: [],
      label: i18n.t('form.store.merchantGuarantee'),
      Object: {
        delete: '_destroy',
        'array-label-width': '120px'
      },
      createDateFirstItem: [{
        span: 24,
        prop: 'remote_icon_url',
        name: 'image',
        value: [],
        label: i18n.t('form.store.logo'),
        Object: {
          limit: 1,
          size: `${i18n.t('form.product.recommendedSize')}: 120x120`
        }
      }, {
        span: 8,
        prop: 'display_order',
        name: 'input',
        type: 'number',
        label: i18n.t('form.product.displayOrder'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 8,
        prop: 'title',
        name: 'input',
        label: i18n.t('form.product.titleCn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 8,
        prop: 'title_en',
        name: 'input',
        label: i18n.t('form.product.titleEn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 24,
        prop: 'detail',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.product.contentCn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }, {
        span: 24,
        prop: 'detail_en',
        name: 'input',
        type: 'textarea',
        label: i18n.t('form.product.contentEn'),
        placeholder: i18n.t('placeholder.defaultInput'),
        Object: {
          maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
        }
      }]
    }]
  }, {
    title: i18n.t('form.store.settlementInfo'),
    name: 8,
    createDateItem: [{
      span: 8,
      prop: 'wechat_fee_rate',
      name: 'input',
      type: 'number',
      value: 2.0,
      label: i18n.t('form.store.wechatFeeRate'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'automatic_paynow_fee_rate',
      name: 'input',
      type: 'number',
      value: 2.0,
      label: i18n.t('form.store.automaticPaynowFeeRate'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'general_commission_rate',
      name: 'input',
      type: 'number',
      value: 2.0,
      label: i18n.t('form.store.generalCommissionRate'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'platform_commission_rate',
      name: 'input',
      type: 'number',
      value: 2.0,
      label: i18n.t('form.store.platformCommissionRate'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'stripe_commission_rate',
      name: 'input',
      type: 'number',
      value: 2.0,
      label: i18n.t('form.store.creditCardsCommissionRate'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }]
  }]
}
